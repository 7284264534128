import React from 'react'
import { TeachersContext } from '../../../contexts/TeachersContext'
import { LocationContext } from '../../../contexts/LocationContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { ClassContext } from '../../../contexts/ClassContext'
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

const AddClass = React.forwardRef((props, ref) => {
    const { db } = React.useContext(FirebaseContext)
    const { closeModal } = props
    const { location } = React.useContext(LocationContext)
    const { teachers } = React.useContext(TeachersContext)
    const { reloadClasses } = React.useContext(ClassContext)
    const [classTeacher, setClassTeacher] = React.useState()

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        db.collection('classes').add({
            name: data.get('name'),
            location: location.id,
            teacher: classTeacher,
            cutoff: data.get('cutoff'),
            active: false,
        })

        reloadClasses()
        closeModal(false)
    }

    const handleChange = (event) => {
        setClassTeacher(event.target.value)
    }

    const getTeachers = React.useCallback(() => {
        if (teachers) {
            const mappedTeachers = []

            teachers.forEach((item) => {
                mappedTeachers.push({
                    label: `${item.firstname} ${item.lastname}`,
                    value: item.id,
                })
            })

            return mappedTeachers
        }
    }, [teachers])

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Add Class
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Class name"
                    name="name"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    fullWidth
                    required
                    id="cutoff"
                    type="time"
                    label="Cut off time"
                    name="cutoff"
                    autoFocus
                />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Teacher
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={classTeacher}
                        label="teacher"
                        onChange={handleChange}
                    >
                        {getTeachers()?.map((item, index) => (
                            <MenuItem
                                key={`teacher-add-${index}`}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    ADD
                </Button>
            </Box>
        </Box>
    )
})

export default AddClass
