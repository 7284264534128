import {
    Card,
    CardContent,
    IconButton,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { TeachersContext } from '../../../contexts/TeachersContext'
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { LocationContext } from '../../../contexts/LocationContext'

const LocationItem = (props) => {
    const { item, onEdit, onDelete } = props
    const { getTeacherById } = React.useContext(TeachersContext)
    const { reloadAll } = React.useContext(LocationContext)
    const [adminUser, setAdminUser] = React.useState()

    React.useEffect(() => {
        ;(async () => {
            const response = await getTeacherById(item?.admin)

            setAdminUser(response)
        })()
    }, [item, reloadAll])

    return (
        <TableRow
            key={item.name}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell component="th" scope="row">
                {item.name}
            </TableCell>
            <TableCell component="th" scope="row">
                {item.timezone}
            </TableCell>
            <TableCell component="th" scope="row">
                {`${adminUser?.firstname} ${adminUser?.lastname}`}
            </TableCell>
            <TableCell>
                <Box
                    space="around"
                    sx={{
                        flexDirection: 'row',
                        display: 'flex',
                    }}
                >
                    <IconButton
                        size="small"
                        sx={{ color: 'text.secondary' }}
                        onClick={() => onEdit(item)}
                    >
                        <ModeEditTwoToneIcon fontSize="medium" />
                    </IconButton>
                    <IconButton
                        size="small"
                        sx={{ color: 'text.primary' }}
                        onClick={() => onDelete(item)}
                    >
                        <BackspaceIcon fontSize="small" />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default LocationItem
