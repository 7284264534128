import React from 'react';
import {FirebaseContext} from './FirebaseContext';

export const GuardianContext = React.createContext();

export const GuardianProvider = ({children}) => {
  const {db} = React.useContext(FirebaseContext);
  const [currentGuardians, setCurrentGuardians] = React.useState();

  const getGuardianByPupil = async pupilId => {
    try {
      const guardianSnap = await db
        .collection('guardians')
        .where('pupil', '==', pupilId)
        .get();

      const guardianMap = [];
      guardianSnap.forEach(doc => {
        guardianMap.push({id: doc.id, ...doc.data()});
      });

      return guardianMap;
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <GuardianContext.Provider
      value={{
        getGuardianByPupil,
        setCurrentGuardians,
        currentGuardians,
      }}>
      {children}
    </GuardianContext.Provider>
  );
};
