import React from 'react'
import { AuthContext } from './AuthContext'
import { FirebaseContext } from './FirebaseContext'

export const UserContext = React.createContext(null)

export const UserProvider = ({ children }) => {
    const { db } = React.useContext(FirebaseContext)
    const { userId } = React.useContext(AuthContext)
    const [user, setUser] = React.useState(null)
    const [reload, setReload] = React.useState(0)

    React.useEffect(() => {
        if (userId) {
            db.collection('users')
                .doc(userId)
                .get()
                .then((doc) => {
                    setUser(doc.data())
                })
                .catch((e) => console.log(e))
        }
    }, [userId, reload])

    const updateUser = (data) => {
        db.collection('users')
            .doc(userId)
            .update(data)
            .catch((e) => console.log(e))
        setReload(reload + 1)
    }

    const getUserById = async (userId) => {
        try {
            const userDoc = await db.collection('users').doc(userId).get()

            return userDoc.data()
        } catch (e) {
            console.log(e.message)
        }
    }

    const addUserData = (data, id) => {
        db.collection('users')
            .doc(id)
            .set(data)
            .catch((e) => console.log(e))
    }

    return (
        <UserContext.Provider
            value={{ user, updateUser, userId, addUserData, getUserById }}
        >
            {children}
        </UserContext.Provider>
    )
}
