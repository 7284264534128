import {
    Button,
    Card,
    CardContent,
    IconButton,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { ClassContext } from '../../../contexts/ClassContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone'
import BackspaceIcon from '@mui/icons-material/Backspace'

const TeacherItem = (props) => {
    const { db } = React.useContext(FirebaseContext)
    const { getTeachersClass } = React.useContext(ClassContext)
    const [classItem, setClassItem] = React.useState()
    const [open, setOpen] = React.useState(false)
    const { item, editHandler, reload, closeModal, deleteHandler } = props

    React.useEffect(() => {
        ;(async () => {
            if (item && item.id) {
                const classData = await getTeachersClass(item.id)

                setClassItem(classData)
            }
        })()
    }, [item])

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography variant="body1">
                        {item.firstname} {item.lastname}
                    </Typography>
                    <Typography sx={{ fontSize: 11 }}>
                        Class:{' '}
                        {classItem?.name ? classItem?.name : 'Unassigned'}
                    </Typography>
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <IconButton
                        size="small"
                        sx={{ color: 'text.secondary' }}
                        onClick={() => editHandler(item)}
                    >
                        <ModeEditTwoToneIcon fontSize="medium" />
                    </IconButton>
                    <IconButton
                        size="small"
                        sx={{ color: 'text.primary' }}
                        onClick={() => deleteHandler(item)}
                    >
                        <BackspaceIcon fontSize="small" />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    )
}

export default TeacherItem
