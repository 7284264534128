import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { FirebaseContext } from '../../../contexts/FirebaseContext'

const AddGuardianItem = (props) => {
    const { db } = React.useContext(FirebaseContext)
    const { pupilId, close } = props
    const [data, setData] = React.useState()

    const handleSubmit = async (event) => {
        event.preventDefault()

        await db
            .collection('guardians')
            .add({
                firstname: data.firstname,
                lastname: data.lastname,
                pupil: pupilId,
                cell: data.cell,
                relation: data.relation,
            })
            .catch((e) => console.log(e.message))

        close()
    }

    const handleChange = (event, prop) => {
        const value = event.target.value

        setData((prevData) => {
            return { ...prevData, [prop]: value }
        })
    }

    return (
        <Box
            sx={{
                bgcolor: '#f3f3f3',
                px: 2,
                mb: 1,
                borderRadius: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    my: 1,
                }}
            >
                <Typography sx={{ textAlign: 'center', mt: 2 }}>
                    Add New Guardian
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First name"
                    name="firstname"
                    onChange={(event) => handleChange(event, 'firstname')}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last name"
                    name="lastname"
                    onChange={(event) => handleChange(event, 'lastname')}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="relation"
                    label="Relation"
                    name="relation"
                    onChange={(event) => handleChange(event, 'relation')}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="cell"
                    label="Cell number"
                    name="cell"
                    onChange={(event) => handleChange(event, 'cell')}
                    autoFocus
                />

                <Button
                    type="submit"
                    onClick={handleSubmit}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white' }}
                >
                    CREATE
                </Button>
            </Box>
        </Box>
    )
}

export default AddGuardianItem
