import React from 'react'
import { LocationContext } from '../../../contexts/LocationContext'
import { UserContext } from '../../../contexts/UserContext'
import { millisecondsToMinutes, minutesToMilliseconds } from 'date-fns'
import { Box } from '@mui/system'
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AuthContext } from '../../../contexts/AuthContext'
import { TeachersContext } from '../../../contexts/TeachersContext'

const timezoneData = [
    { value: 'America/New_York', label: 'New York' },
    { value: 'America/Chicago', label: 'Chicago' },
    { value: 'America/LA', label: 'LA' },
]

const EditSchool = React.forwardRef((props, ref) => {
    const { closeModal, item, adminUser } = props
    const { updateLocation, revalidateAll, location } =
        React.useContext(LocationContext)
    const { user, updateUser } = React.useContext(UserContext)
    const { createUser } = React.useContext(AuthContext)
    const [timezone, setTimezone] = React.useState(item?.timezone)
    const [validPassword, setValidPassword] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        if (data.get('password') !== data.get('confirm-password')) {
            setValidPassword(true)
            return
        }

        await updateLocation({
            name: data.get('name'),
            timezone,
        })

        updateUser(
            {
                firstname: data.get('firstname'),
                lastname: data.get('lastname'),
                location: location.id,
                email: data.get('email'),
                role: adminUser.role,
                password: data.get('password'),
            },
            user.uid
        )

        revalidateAll()
        closeModal(false)
    }

    const handleChange = (event) => {
        setTimezone(event.target.value)
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 3,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Edit School
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="School name"
                    name="name"
                    autoFocus
                    defaultValue={item?.name}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                        Time zone
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={timezone}
                        label="Time zone"
                        onChange={handleChange}
                        displayEmpty
                    >
                        {timezoneData?.map((item, index) => (
                            <MenuItem
                                key={`timezone-add-${index}`}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="Admin first name"
                    name="firstname"
                    autoFocus
                    defaultValue={adminUser?.firstname}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Admin last name"
                    name="lastname"
                    autoFocus
                    defaultValue={adminUser?.lastname}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Admin email"
                    name="email"
                    autoFocus
                    defaultValue={adminUser?.email}
                />
                <TextField
                    error={validPassword}
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Admin password"
                    name="password"
                    type="password"
                    autoFocus
                />
                <TextField
                    error={validPassword}
                    id="confirm-password"
                    type="password"
                    autoComplete="current-password"
                    name="confirm-password"
                    autoFocus
                    fullWidth
                    required
                    margin="normal"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    EDIT
                </Button>
            </Box>
        </Box>
    )
})

export default EditSchool
