import React from 'react'
import { TeachersContext } from '../../../contexts/TeachersContext'
import { LocationContext } from '../../../contexts/LocationContext'
import { PupilContext } from '../../../contexts/PupilContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { GuardianContext } from '../../../contexts/GuardianContext'
import GuardianItemPanel from '../components/GuardianItemPanel'
import { Button, IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

const AddPupil = React.forwardRef((props, ref) => {
    const { db } = React.useContext(FirebaseContext)
    const { closeModal } = props
    const { pupils, currentClass, reloadPupils, generatePupilId } =
        React.useContext(PupilContext)
    const { currentGuardians, setCurrentGuardians } =
        React.useContext(GuardianContext)
    const { location } = React.useContext(LocationContext)
    const [pupilId, setPupilId] = React.useState()
    const [firstname, setFirstname] = React.useState()
    const [lastname, setLastname] = React.useState()

    React.useEffect(() => {
        setCurrentGuardians()

        const newId = generatePupilId()
        setPupilId(newId)

        db.collection('pupils').doc(newId).set({
            location: location.id,
            class: currentClass.id,
        })
    }, [])

    const handleForm = () => {
        db.collection('pupils').doc(pupilId).update({
            firstname,
            lastname,
        })

        handleSubmit()
    }

    const handleChange = (event, prop) => {
        event.preventDefault()
        const value = event.currentTarget.value

        db.collection('pupils')
            .doc(pupilId)
            .update({ [prop]: value })
    }

    const handleSubmit = () => {
        reloadPupils()
        closeModal(false)
    }

    const handleRemove = async () => {
        await db
            .collection('pupils')
            .doc(pupilId)
            .delete()
            .catch((e) => console.log(e.message))

        handleSubmit()
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
            >
                <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                        sx={{
                            bgcolor: 'white',
                        }}
                        size="large"
                        onClick={handleRemove}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    <Typography fontWeight="600" sx={{ fontSize: 20 }}>
                        Add Student
                    </Typography>
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First name"
                    name="firstname"
                    onChange={(event) => handleChange(event, 'firstname')}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last name"
                    name="lastname"
                    onChange={(event) => handleChange(event, 'lastname')}
                    autoFocus
                />
                <GuardianItemPanel
                    currentGuardians={currentGuardians}
                    currentPupilId={pupilId}
                    reloadPupils={reloadPupils}
                    closeModal={closeModal}
                />
                <Button
                    onClick={handleSubmit}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    ADD
                </Button>
            </Box>
        </Box>
    )
})

export default AddPupil
