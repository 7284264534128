import { Button, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import AddGuardianItem from './AddGuardianItem'
import EditGuardianItem from './EditGuardianItem'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

const GuardianItemPanel = (props) => {
    const { currentGuardians, currentPupilId, reloadPupils, closeModal } = props
    const [openItem, setOpenItem] = React.useState()
    const [openAddGuardian, setOpenAddGuardian] = React.useState(false)

    const handleRemove = (itemIndex) => {
        reloadPupils()
        setOpenAddGuardian(false)
        closeModal(false)
    }

    const handleOpen = (itemIndex) => {
        const openItemMap = []

        for (let index = 0; index < currentGuardians.length; index++) {
            openItemMap.push(index === itemIndex)
        }

        setOpenItem(openItemMap)
    }

    const handleCloseAddGuardian = () => {
        reloadPupils()
        setOpenAddGuardian(false)
        closeModal(false)
    }

    return (
        <Box>
            <Box
                row
                space="around"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    justifyContent: 'left',
                }}
            >
                <IconButton
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        my: 2,
                    }}
                    onClick={() => setOpenAddGuardian(!openAddGuardian)}
                >
                    <AddRoundedIcon
                        fontSize="large"
                        sx={{
                            color: 'primary.main',
                            width: 16,
                            height: 16,
                            borderRadius: 20,
                            borderWidth: 2,
                            borderColor: 'primary.main',
                            borderStyle: 'solid',
                        }}
                    />
                    <Typography sx={{ ml: 1 }}>Add Guardian</Typography>
                </IconButton>
            </Box>
            {currentGuardians &&
                !openAddGuardian &&
                currentGuardians.map((gItem, index) => (
                    <EditGuardianItem
                        key={`edit-${gItem}-${index}`}
                        item={gItem}
                        open={openItem ? openItem[index] : false}
                        setOpen={() => handleOpen(index)}
                        setClosed={() => handleOpen(-1)}
                        removeItem={() => handleRemove(index)}
                    />
                ))}
            {openAddGuardian && (
                <AddGuardianItem
                    pupilId={currentPupilId}
                    close={() => handleCloseAddGuardian()}
                />
            )}
        </Box>
    )
}

export default GuardianItemPanel
