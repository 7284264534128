import React from 'react'
import { TeachersContext } from '../../../contexts/TeachersContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { Box } from '@mui/system'
import { Button, IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const EditTeacher = React.forwardRef((props, ref) => {
    const { db } = React.useContext(FirebaseContext)
    const { closeModal, teacherId } = props
    const { currentTeacher, reloadTeachers } = React.useContext(TeachersContext)
    const [passError, setPassError] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const email = data.get('email').toLowerCase()
        const password = data.get('password')
        const confirmPassword = data.get('confirm-password')

        if (password && confirmPassword !== password) {
            setPassError(true)
            return
        }

        await db
            .collection('users')
            .doc(currentTeacher.id)
            .update({
                firstname: data.get('firstname'),
                lastname: data.get('lastname'),
                email,
                password,
            })

        reloadTeachers()
        closeModal(false)
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Edit Teacher
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First name"
                    name="firstname"
                    autoFocus
                    defaultValue={currentTeacher?.firstname}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last name"
                    name="lastname"
                    autoFocus
                    defaultValue={currentTeacher?.lastname}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoFocus
                    defaultValue={currentTeacher?.email || ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="current-password"
                    type="password"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="confirm-password"
                    label="Confirm password"
                    name="confirm-password"
                    autoComplete="current-password"
                    type="password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    UPDATE
                </Button>
            </Box>
        </Box>
    )
})

export default EditTeacher
