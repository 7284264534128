import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from '../contexts/UserContext'
import Login from './Login'

const PrivateRoute = ({ Component, title, ...rest }) => {
    const { userId } = React.useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                return userId ? (
                    <Component {...props} title={title} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                )
            }}
        />
    )
}

export default PrivateRoute
