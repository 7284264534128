import { Card, CardContent, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { GuardianContext } from '../../../contexts/GuardianContext'
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone'
import BackspaceIcon from '@mui/icons-material/Backspace'
import GuardianItem from './GuardianItem'

const PupilItem = (props) => {
    const { db } = React.useContext(FirebaseContext)
    const { item, editHandler, reload, deleteHandler } = props
    const { getGuardianByPupil } = React.useContext(GuardianContext)
    const [guardians, setGuardians] = React.useState()

    React.useEffect(() => {
        ;(async () => {
            if (item?.id) {
                const guardiansResponse = await getGuardianByPupil(item.id)

                setGuardians(guardiansResponse)
            }
        })()
    }, [item])

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography variant="body1">
                        {item.firstname} {item.lastname}
                    </Typography>
                    {guardians &&
                        guardians.map((gItem, index) => (
                            <GuardianItem
                                key={`gitem-${gItem.id}-${index}`}
                                item={gItem}
                            />
                        ))}
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <IconButton
                        size="small"
                        sx={{ color: 'text.secondary' }}
                        onClick={() => editHandler(item.id, guardians)}
                    >
                        <ModeEditTwoToneIcon fontSize="medium" />
                    </IconButton>
                    <IconButton
                        size="small"
                        sx={{ color: 'text.primary' }}
                        onClick={() => deleteHandler(item.id)}
                    >
                        <BackspaceIcon fontSize="small" />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    )
}

export default PupilItem
