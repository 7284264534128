import React from 'react'
import 'firebase'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { Box, Container, createTheme, Link, ThemeProvider } from '@mui/material'
import Navigation from './navigation'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AdminHome from './routes/AdminHome'
import Teachers from './routes/Teachers'
import Classes from './routes/Classes'
import Pupils from './routes/Pupils'
import { FirebaseProvider } from './contexts/FirebaseContext'
import { LocationProvider } from './contexts/LocationContext'
import { AuthProvider } from './contexts/AuthContext'
import { UserProvider } from './contexts/UserContext'
import { TeachersProvider } from './contexts/TeachersContext'
import { ClassProvider } from './contexts/ClassContext'
import { PupilProvider } from './contexts/PupilContext'
import { GuardianProvider } from './contexts/GuardianContext'
import Login from './routes/Login'
import PrivateRoute from './routes/PrivateRoute'
import Rollcalls from './routes/Rollcalls'
import { RollcallProvider } from './contexts/RollcallContext'
import { MessageProvider } from './contexts/MessageContext'
import './App.css'

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#00ABAF',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: 'Montserrat',
    },
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <FirebaseProvider>
                <AuthProvider>
                    <UserProvider>
                        <LocationProvider>
                            <TeachersProvider>
                                <ClassProvider>
                                    <PupilProvider>
                                        <GuardianProvider>
                                            <RollcallProvider>
                                                <MessageProvider>
                                                    <Router>
                                                        <Container
                                                            className="App"
                                                            sx={{
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <Navigation />
                                                            <Switch>
                                                                <PrivateRoute
                                                                    exact
                                                                    path="/teachers"
                                                                    Component={
                                                                        Teachers
                                                                    }
                                                                    title="Teachers"
                                                                />
                                                                <PrivateRoute
                                                                    exact
                                                                    path="/classes"
                                                                    Component={
                                                                        Classes
                                                                    }
                                                                    title="Classes"
                                                                />
                                                                <PrivateRoute
                                                                    exact
                                                                    path="/students"
                                                                    Component={
                                                                        Pupils
                                                                    }
                                                                    title="Students"
                                                                />
                                                                <PrivateRoute
                                                                    exact
                                                                    path="/rollcall"
                                                                    Component={
                                                                        Rollcalls
                                                                    }
                                                                    title="Roll call"
                                                                />
                                                                <PrivateRoute
                                                                    exact
                                                                    path="/home"
                                                                    Component={
                                                                        AdminHome
                                                                    }
                                                                    title="Dashboard"
                                                                />
                                                                <Route
                                                                    exact
                                                                    path="/"
                                                                >
                                                                    <Login />
                                                                </Route>
                                                            </Switch>
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    textAlign:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                    my: 5,
                                                                }}
                                                            >
                                                                To gain access
                                                                or to learn more
                                                                about the
                                                                Arrived App,
                                                                please visit{' '}
                                                                <Link
                                                                    href="https://get.arrivedapp.com"
                                                                    target={
                                                                        '_blank'
                                                                    }
                                                                >
                                                                    get.arrivedapp.com
                                                                </Link>
                                                            </Box>
                                                        </Container>
                                                    </Router>
                                                </MessageProvider>
                                            </RollcallProvider>
                                        </GuardianProvider>
                                    </PupilProvider>
                                </ClassProvider>
                            </TeachersProvider>
                        </LocationProvider>
                    </UserProvider>
                </AuthProvider>
            </FirebaseProvider>
        </ThemeProvider>
    )
}

export default App
