import React from 'react'
import { LocationContext } from '../../../contexts/LocationContext'
import { UserContext } from '../../../contexts/UserContext'
import { millisecondsToMinutes, minutesToMilliseconds } from 'date-fns'
import { Box } from '@mui/system'
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AuthContext } from '../../../contexts/AuthContext'

const timezoneData = [
    { value: 'America/New_York', label: 'New York' },
    { value: 'America/Chicago', label: 'Chicago' },
    { value: 'America/LA', label: 'LA' },
]

const AddSchool = React.forwardRef((props, ref) => {
    const { closeModal } = props
    const { createNewLocation, revalidateAll, addAdminToLocation } =
        React.useContext(LocationContext)
    const { user, addUserData } = React.useContext(UserContext)
    const { createUser } = React.useContext(AuthContext)
    const [timezone, setTimezone] = React.useState('America/Florida')
    const [validPassword, setValidPassword] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        if (data.get('password') !== data.get('confirm-password')) {
            setValidPassword(true)
            return
        }

        const locRef = await createNewLocation({
            name: data.get('name'),
            timezone,
        })

        const locId = locRef.id

        await createUser(data.get('email'), data.get('password')).then(
            (userCredential) => {
                const user = userCredential.user

                addUserData(
                    {
                        firstname: data.get('firstname'),
                        lastname: data.get('lastname'),
                        location: locId,
                        email: data.get('email'),
                        role: 'admin',
                    },
                    user.uid
                )

                addAdminToLocation(user.uid, locId)
            }
        )

        revalidateAll()
        closeModal(false)
    }

    const handleChange = (event) => {
        setTimezone(event.target.value)
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Add School
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="School name"
                    name="name"
                    autoFocus
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                        Time zone
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={timezone}
                        label="Time zone"
                        onChange={handleChange}
                    >
                        {timezoneData?.map((item, index) => (
                            <MenuItem
                                key={`timezone-add-${index}`}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="Admin first name"
                    name="firstname"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Admin last name"
                    name="lastname"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Admin email"
                    name="email"
                    autoFocus
                />
                <TextField
                    error={validPassword}
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Admin password"
                    name="password"
                    type="password"
                    autoFocus
                />
                <TextField
                    error={validPassword}
                    id="confirm-password"
                    type="password"
                    autoComplete="current-password"
                    name="confirm-password"
                    autoFocus
                    fullWidth
                    required
                    margin="normal"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    CREATE
                </Button>
            </Box>
        </Box>
    )
})

export default AddSchool
