import { IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import BackspaceIcon from '@mui/icons-material/Backspace'

const EditGuardianItem = (props) => {
    const { db } = React.useContext(FirebaseContext)
    const { item, open, setOpen, removeItem, setClosed } = props

    const deleteGuardian = (guardianId) => {
        db.collection('guardians')
            .doc(guardianId)
            .delete()
            .catch((e) => console.log(e.message))

        removeItem()
    }

    const handleChange = (event, prop) => {
        event.preventDefault()
        const value = event.target.value

        db.collection('guardians')
            .doc(item.id)
            .update({
                [prop]: value,
            })
    }

    return (
        <Box
            sx={{
                bgcolor: '#f3f3f3',
                px: 1,
                mb: 1,
                borderRadius: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    my: 3,
                    py: 1,
                }}
            >
                <Typography fontSize="small" sx={{ marginRight: 5 }}>
                    {item.firstname} {item.lastname}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {!open && (
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={setOpen}
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                    )}
                    {open && (
                        <IconButton
                            size="small"
                            sx={{ color: 'text.secondary' }}
                            onClick={setClosed}
                        >
                            <KeyboardArrowUpIcon />
                        </IconButton>
                    )}
                    <IconButton
                        sx={{ color: 'text.main' }}
                        size="small"
                        onClick={() => deleteGuardian(item.id)}
                    >
                        <BackspaceIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
            {open && (
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                    <TextField
                        margin="normal"
                        required
                        id="firstname"
                        label="First name"
                        name="firstname"
                        onChange={(event) => handleChange(event, 'firstname')}
                        defaultValue={item.firstname}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        id="lastname"
                        label="Last name"
                        name="lastname"
                        onChange={(event) => handleChange(event, 'lastname')}
                        defaultValue={item.lastname}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        id="relation"
                        label="Relation"
                        name="relation"
                        onChange={(event) => handleChange(event, 'relation')}
                        defaultValue={item?.relation}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        id="cell"
                        label="Cell number"
                        name="cell"
                        onChange={(event) => handleChange(event, 'cell')}
                        defaultValue={item?.cell}
                        autoFocus
                    />
                </Box>
            )}
        </Box>
    )
}

export default EditGuardianItem
