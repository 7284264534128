import React from 'react'
import { FirebaseContext } from './FirebaseContext'

const CHANNEL_ID = '8d23a207262b41f29c68597ae1dfd820'

export const MessageContext = React.createContext()

export const MessageProvider = ({ children }) => {
    const { db } = React.useContext(FirebaseContext)

    const sendArrivedMessage = async (pupilId, pupilData) => {
        const guardianQuery = await db
            .collection('guardians')
            .where('pupil', '==', pupilId)
            .get()

        if (!guardianQuery.empty) {
            await guardianQuery.forEach(async (guardDoc) => {
                const guardianCell = guardDoc.data().cell

                await db.collection('messages').add({
                    channelId: CHANNEL_ID,
                    to: guardianCell,
                    type: 'text',
                    content: {
                        text: `${pupilData.firstname} ${pupilData.lastname} has arrived at school!`,
                    },
                })
            })
        }
    }

    return (
        <MessageContext.Provider value={{ sendArrivedMessage }}>
            {children}
        </MessageContext.Provider>
    )
}
