import React from 'react'
import {
    Divider,
    Drawer,
    List,
    ListItemIcon,
    ListItemText,
    Button,
    Box,
    IconButton,
    ListItemButton,
    AppBar,
    Typography,
    Toolbar,
    Select,
    MenuItem,
} from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import logo from '../assets/arrivedlogo.jpg'
import bgLogo from '../assets/arrived-bg.jpg'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../contexts/UserContext'
import { AuthContext } from '../contexts/AuthContext'
import { LocationContext } from '../contexts/LocationContext'
import { ClassContext } from '../contexts/ClassContext'
import { useRouteMatch } from 'react-router-dom'

const Navigation = (props) => {
    const history = useHistory()
    const [open, setOpen] = React.useState(true)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [selectedTitle, setSelectedTitle] = React.useState('Home')
    const [navItems, setNavItems] = React.useState()
    const [mappedClasses, setMappedClasses] = React.useState([])
    const [locationList, setLocationList] = React.useState([])
    const { userId, user } = React.useContext(UserContext)
    const { signOut } = React.useContext(AuthContext)
    const { location, setLocationById, allLocations } =
        React.useContext(LocationContext)
    const { classes, setClassById, currentClass, reloadClasses } =
        React.useContext(ClassContext)

    let match = useRouteMatch('/rollcall')

    React.useEffect(() => {
        if (user) {
            setSelectedIndex(0)

            switch (user?.role) {
                case 'admin':
                    setNavItems(['Home', 'Teachers', 'Classes', 'Students'])
                    break
                case 'teacher':
                    setNavItems(['Home', 'Students'])
                    break
                case 'superadmin':
                    setNavItems(['Home', 'Teachers', 'Classes', 'Students'])
                    break
            }
        }
    }, [user, userId])

    React.useEffect(() => {
        if (classes) {
            const classArray = []

            classes.forEach((item) => {
                classArray.push({
                    label: item.name,
                    value: item.id,
                })
            })

            setMappedClasses(classArray)
        }
    }, [classes])

    React.useEffect(() => {
        if (allLocations) {
            const mappedLocations = []

            allLocations.forEach((item) => {
                mappedLocations.push({
                    label: item.name,
                    value: item.id,
                })
            })

            setLocationList(mappedLocations)
        }
    }, [allLocations])

    const handleClick = (event, index, name) => {
        setSelectedIndex(index)
        setSelectedTitle(name)
        history.push(`/${name.toLowerCase()}`)
    }

    const handleChange = (event) => {
        const locId = event.target.value

        setLocationById(locId)
        reloadClasses()
    }

    const handleClassChange = (event) => {
        const classId = event.target.value

        setClassById(classId)
    }

    if (!userId) {
        return null
    }

    return (
        <React.Fragment>
            <AppBar
                position="relative"
                sx={{
                    height: 80,
                    my: 5,
                    flexDirection: 'row',
                    flex: 1,
                    borderRadius: 2,
                    boxShadow: 0,
                }}
            >
                <Toolbar
                    sx={{ alignItems: 'center', flex: 1, flexDirection: 'row' }}
                >
                    <img
                        style={{ margin: 20 }}
                        width="150px"
                        src={logo}
                        alt="logo"
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <List
                            sx={{
                                flexDirection: 'row',
                                display: 'flex',
                                ml: 5,
                                flex: 1,
                            }}
                        >
                            {navItems?.map((text, index) => (
                                <ListItemButton
                                    key={text}
                                    sx={{
                                        bgcolor:
                                            selectedIndex === index
                                                ? 'white'
                                                : 'inherit',
                                        maxWidth: 100,
                                        borderRadius: 1,
                                        display: 'inline-table',
                                    }}
                                    onClick={(event) =>
                                        handleClick(event, index, text)
                                    }
                                >
                                    <ListItemText
                                        sx={{
                                            color:
                                                selectedIndex === index
                                                    ? 'black'
                                                    : 'white',
                                            textAlign: 'center',
                                        }}
                                        primary={text}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={() => signOut()}>
                            <LogoutIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {user?.role === 'superadmin' && (
                <Select
                    autoWidth
                    sx={{
                        minWidth: 250,
                        bgcolor: 'white',
                        marginBottom: 4,
                    }}
                    id="locations"
                    onChange={handleChange}
                >
                    {locationList?.map((item, index) => (
                        <MenuItem
                            key={`location-selector-item-${index}`}
                            value={item.value}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {user?.role === 'teacher' && (
                <Select
                    autoWidth
                    disabled={match}
                    sx={{
                        minWidth: 250,
                        bgcolor: 'white',
                        marginBottom: 4,
                    }}
                    id="class"
                    onChange={handleClassChange}
                    value={currentClass?.id}
                >
                    {mappedClasses?.map((item, index) => (
                        <MenuItem
                            key={`class-selector-item-${index}`}
                            value={item.value}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </React.Fragment>
    )
}

export default Navigation
