import {
    Button,
    List,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { LocationContext } from '../../contexts/LocationContext'
import LocationItem from './components/LocationItem'
import AddSchool from './modals/AddSchool'
import EditSchool from './modals/EditSchool'
import '../../App.css'

const SuperAdminHome = (props) => {
    const { title, user } = props
    const { allLocations, deleteLocation, getAdminUserById } =
        React.useContext(LocationContext)
    const [modalVisible, setModalVisible] = React.useState(false)
    const [modalType, setModalType] = React.useState('add')
    const [currentSchool, setCurrentSchool] = React.useState()
    const [adminUser, setAdminUser] = React.useState()

    const renderLocation = (item) => <LocationItem key={item.id} item={item} />

    const handleEdit = async (item) => {
        const userResponse = await getAdminUserById(item?.admin)

        setAdminUser(userResponse)
        setCurrentSchool(item)
        setModalType('edit')
        setModalVisible(true)
    }

    const handleAdd = () => {
        setModalType('add')
        setModalVisible(true)
    }

    const handleDelete = (item) => {
        deleteLocation(item)
    }

    return (
        <Box
            className="shadowFX"
            sx={{
                height: 'auto',
                bgcolor: 'white',
                borderRadius: 3,
                p: 5,
            }}
        >
            <Typography fontWeight="600" sx={{ fontSize: 30 }}>
                {title}
            </Typography>
            <Box
                sx={{ mt: 2, fontSize: 'body.fontSize', fontWeight: 'normal' }}
            >
                Welcome super administrator! This is the place to add a new
                school location and set up the schools administrator details.
            </Box>
            <Box flexGrow={1} height={50} />
            <Button
                variant="contained"
                sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                onClick={handleAdd}
            >
                ADD NEW SCHOOL
            </Button>
            <Box
                sx={{
                    mt: 8,
                    mb: 3,
                    fontSize: 'body.fontSize',
                    fontWeight: 'bold',
                }}
            >
                Current Schools
            </Box>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>School Name</TableCell>
                            <TableCell>Time zone</TableCell>
                            <TableCell>School Administrator</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allLocations?.map((row) => (
                            <LocationItem
                                key={row.id}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                                item={row}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
                {modalType === 'add' ? (
                    <AddSchool closeModal={setModalVisible} />
                ) : (
                    <EditSchool
                        item={currentSchool}
                        adminUser={adminUser}
                        closeModal={setModalVisible}
                    />
                )}
            </Modal>
        </Box>
    )
}

export default SuperAdminHome
