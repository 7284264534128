import React from 'react'
import { TeachersContext } from '../../../contexts/TeachersContext'
import { LocationContext } from '../../../contexts/LocationContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { Box } from '@mui/system'
import { Button, IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const AddTeacher = React.forwardRef((props, ref) => {
    const { db } = React.useContext(FirebaseContext)
    const { closeModal } = props
    const { reloadTeachers } = React.useContext(TeachersContext)
    const { location } = React.useContext(LocationContext)

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        await db.collection('users').add({
            firstname: data.get('firstname'),
            lastname: data.get('lastname'),
            email: data.get('email').toLowerCase(),
            location: location.id,
            role: 'teacher',
        })

        reloadTeachers()
        closeModal(false)
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Add Teacher
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First name"
                    name="firstname"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last name"
                    name="lastname"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    ADD
                </Button>
            </Box>
        </Box>
    )
})

export default AddTeacher
