import { Box } from '@mui/system'
import {
    Button,
    Container,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { LocationContext } from '../../contexts/LocationContext'
import { UserContext } from '../../contexts/UserContext'
import EditAdmin from './modals/EditAdmin'
import SuperAdminHome from './SuperAdminHome'
import { ClassContext } from '../../contexts/ClassContext'
import { RollcallContext } from '../../contexts/RollcallContext'
import RollcallItem from './components/RollcallItem'
import EditTeacher from './modals/EditTeacher'

const AdminHome = (props) => {
    const { title } = props
    const { location } = React.useContext(LocationContext)
    const { user, userId } = React.useContext(UserContext)
    const { currentClass, setClassById } = React.useContext(ClassContext)
    const { getRollcallByClassId, setCurrentRollId } =
        React.useContext(RollcallContext)
    const [modalVisible, setModalVisible] = React.useState(false)
    const [rollcalls, setRollcalls] = React.useState()

    const history = useHistory()

    React.useEffect(() => {
        if (currentClass) {
            ;(async () => {
                if (userId) {
                    const rollcallResponse = await getRollcallByClassId(
                        currentClass.id
                    )

                    setRollcalls(rollcallResponse)
                }
            })()
        }
    }, [currentClass])

    const handleEdit = async (item) => {
        setClassById(item.class)
        setCurrentRollId(item.id)
        history.push(`/rollcall`)
    }

    if (user?.role === 'superadmin') {
        return <SuperAdminHome {...props} user={user} />
    }

    return (
        <Box
            className="shadowFX"
            sx={{
                height: 'auto',
                bgcolor: 'white',
                borderRadius: 3,
                p: 5,
            }}
        >
            <Typography fontWeight="600" sx={{ fontSize: 30 }}>
                {title}
            </Typography>
            <Box
                sx={{ mt: 2, fontSize: 'body.fontSize', fontWeight: 'normal' }}
            >
                Welcome, {user?.firstname} {user?.lastname}, to the{' '}
                {location?.name} schools roll call site.
            </Box>
            <Box flexGrow={1} height={50} />
            {user?.role === 'admin' && (
                <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                    onClick={() => setModalVisible(true)}
                >
                    EDIT SCHOOL
                </Button>
            )}
            {user?.role === 'teacher' && (
                <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                    onClick={() => setModalVisible(true)}
                >
                    EDIT DETAILS
                </Button>
            )}
            {user?.role === 'teacher' && currentClass && (
                <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                    onClick={() => history.push(`/rollcall`)}
                >
                    START ROLL CALL
                </Button>
            )}
            {user?.role === 'teacher' && (
                <>
                    <Box
                        sx={{
                            mt: 8,
                            mb: 3,
                            fontSize: 'body.fontSize',
                            fontWeight: 'bold',
                        }}
                    >
                        Roll calls
                    </Box>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Class</TableCell>
                                    <TableCell>Time started</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rollcalls?.map((row) => (
                                    <RollcallItem
                                        key={row.id}
                                        onEdit={handleEdit}
                                        item={row}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            {user?.role === 'admin' && (
                <Modal
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                >
                    <EditAdmin closeModal={setModalVisible} />
                </Modal>
            )}
            {user?.role === 'teacher' && (
                <Modal
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                >
                    <EditTeacher closeModal={setModalVisible} />
                </Modal>
            )}
        </Box>
    )
}

export default AdminHome
