import React from 'react'
import { FirebaseContext } from './FirebaseContext'
import { LocationContext } from './LocationContext'
import { UserContext } from './UserContext'

export const TeachersContext = React.createContext(null)

export const TeachersProvider = ({ children }) => {
    const { db } = React.useContext(FirebaseContext)
    const { user } = React.useContext(UserContext)
    const { location } = React.useContext(LocationContext)
    const [currentTeacher, setCurrentTeacher] = React.useState()
    const [teachers, setTeachers] = React.useState(null)
    const [reload, setReload] = React.useState(0)

    React.useEffect(() => {
        if (user?.location && location) {
            const teacherMap = []
            const userLoc =
                user?.role === 'superadmin' ? location.id : user.location

            db.collection('users')
                .where('location', '==', userLoc)
                .where('role', '==', 'teacher')
                .get()
                .then((query) => {
                    query.forEach((doc) => {
                        teacherMap.push({ id: doc.id, ...doc.data() })
                    })

                    setTeachers(teacherMap)
                })
        }
    }, [user?.location, reload, location])

    const getTeacherById = async (teacherId) => {
        try {
            const teacherDoc = await db.collection('users').doc(teacherId).get()

            return { id: teacherId, ...teacherDoc.data() }
        } catch (e) {
            console.log(e.message)
        }
    }

    const reloadTeachers = () => {
        setReload((prev) => prev + 1)
    }

    return (
        <TeachersContext.Provider
            value={{
                teachers,
                currentTeacher,
                setCurrentTeacher,
                reloadTeachers,
                getTeacherById,
            }}
        >
            {children}
        </TeachersContext.Provider>
    )
}
