import { Box } from '@mui/system'
import { Button, Container, List, Typography } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'
import { PupilContext } from '../../contexts/PupilContext'
import { LocationContext } from '../../contexts/LocationContext'
import { RollcallContext } from '../../contexts/RollcallContext'
import RollcallItem from './components/RollcallItem'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'

const Rollcalls = (props) => {
    const { title } = props
    const [currentDatetime, setCurrentDatetime] = React.useState(
        format(new Date(), 'do LLL yyyy - hh:mm:ss aa')
    )
    const [selectedPupils, setSelectedPupils] = React.useState()
    const { pupils, currentClass } = React.useContext(PupilContext)
    const { location } = React.useContext(LocationContext)
    const {
        createNewRollcall,
        setCurrentRollId,
        currentRollId,
        getRollcallPupilById,
        getPupilsByRollId,
        updateRollcallPupils,
        getRollcallById,
    } = React.useContext(RollcallContext)
    const [resetKey, setResetKey] = React.useState(0)
    const history = useHistory()

    React.useEffect(() => {
        setResetKey((prevKey) => prevKey + 1)

        if (currentRollId) {
            ;(async () => {
                const rollData = await getRollcallById(currentRollId)
                const pupilsData = await getPupilsByRollId(currentRollId)

                setSelectedPupils(pupilsData)
                setCurrentDatetime(rollData.calltime)
            })()
        } else {
            const timeNow = format(new Date(), 'do LLL yyyy - hh:mm:ss aa')

            setCurrentDatetime(timeNow)
            createNewRollcall(timeNow, currentClass.id, location.id)
        }
    }, [currentRollId])

    const onSelect = (itemId, data) => {
        const selectedPupilsClone = selectedPupils || {}
        selectedPupilsClone[itemId] = data

        setSelectedPupils(selectedPupilsClone)
    }

    const renderRollcall = (item) => (
        <RollcallItem
            key={item.id}
            item={item}
            edit={false}
            isSelected={
                selectedPupils != null ? selectedPupils[item.id]?.arrived : null
            }
            handleSelected={onSelect}
        />
    )

    const handleFinished = () => {
        if (selectedPupils) {
            updateRollcallPupils(selectedPupils)
        }

        setCurrentRollId(null)
        setSelectedPupils(null)
        history.push('/home')
    }

    return (
        <Box
            sx={{
                height: '100%',
                bgcolor: '#F5F5F5',
                borderRadius: 3,
                p: 2,
            }}
        >
            <Box sx={{ m: 5 }}>
                <Typography>{currentClass?.name}</Typography>
                <Typography fontSize="large" fontWeight="bold">
                    {currentDatetime}
                </Typography>
                <Typography>Cut off time: {currentClass?.cutoff}</Typography>
                <List sx={{ mt: 3 }}>
                    {pupils?.map((item, index) => renderRollcall(item))}
                </List>
                <Button
                    onClick={handleFinished}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    FINISH
                </Button>
            </Box>
        </Box>
    )
}

export default Rollcalls
