import React from 'react'
import { ClassContext } from './ClassContext'
import { FirebaseContext } from './FirebaseContext'
import uuid from 'react-native-uuid'

export const PupilContext = React.createContext(null)

export const PupilProvider = ({ children }) => {
    const { db } = React.useContext(FirebaseContext)
    const { currentClass } = React.useContext(ClassContext)
    const [currentPupilId, setCurrentPupilId] = React.useState(null)
    const [pupils, setPupils] = React.useState(null)
    const [reload, setReload] = React.useState(0)

    React.useEffect(() => {
        if (currentClass?.id) {
            const pupilMap = []

            db.collection('pupils')
                .where('class', '==', currentClass?.id)
                .get()
                .then((query) => {
                    query.forEach((doc) => {
                        pupilMap.push({ id: doc.id, ...doc.data() })
                    })

                    setPupils(pupilMap)
                })
                .catch((e) => console.log(e))
        }
    }, [currentClass?.id, reload])

    const reloadPupils = () => {
        setReload((prev) => prev + 1)
    }

    const generatePupilId = () => {
        return uuid.v4()
    }

    return (
        <PupilContext.Provider
            value={{
                pupils,
                reloadPupils,
                currentPupilId,
                setCurrentPupilId,
                currentClass,
                generatePupilId,
            }}
        >
            {children}
        </PupilContext.Provider>
    )
}
