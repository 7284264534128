import {
    Button,
    Card,
    CardContent,
    IconButton,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { ClassContext } from '../../../contexts/ClassContext'
import CheckIcon from '@mui/icons-material/Check'
import { MessageContext } from '../../../contexts/MessageContext'

const RollcallItem = (props) => {
    const { item, handleSelected, edit, isSelected } = props
    const [selected, setSelected] = React.useState(edit ? isSelected : false)
    const [messageSent, setMessageSent] = React.useState(false)
    const { sendArrivedMessage } = React.useContext(MessageContext)

    React.useEffect(() => {
        if (isSelected != null) {
            setSelected(isSelected)
        } else {
            setSelected(null)
        }
    }, [isSelected, edit])

    const handleArrived = () => {
        const currentlySelected = !selected
        setSelected(currentlySelected)
        handleSelected(item.id, { arrived: currentlySelected })

        if (currentlySelected && !messageSent) {
            setMessageSent(true)
            sendArrivedMessage(item.id, item)
        }
    }

    return (
        <Button
            onClick={handleArrived}
            sx={{ mb: 2, display: 'block', width: '100%' }}
        >
            <Card
                variant="outlined"
                sx={{ bgcolor: selected && 'success.light', p: 1 }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography color={selected ? 'white' : 'black'}>
                        {item.firstname} {item.lastname}
                    </Typography>
                    <CheckIcon sx={{ color: selected ? 'white' : 'black' }} />
                </CardContent>
            </Card>
        </Button>
    )
}

export default RollcallItem
