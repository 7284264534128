import { Box } from '@mui/system'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    MenuItem,
    Modal,
    Select,
    Typography,
} from '@mui/material'
import React from 'react'
import { PupilContext } from '../../contexts/PupilContext'
import { GuardianContext } from '../../contexts/GuardianContext'
import { UserContext } from '../../contexts/UserContext'
import { ClassContext } from '../../contexts/ClassContext'
import PupilItem from './components/PupilItem'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import AddPupil from './modals/AddPupil'
import EditPupil from './modals/EditPupil'

const Pupils = (props) => {
    const { title } = props
    const {
        pupils,
        setCurrentPupilId,
        currentClass,
        reloadPupils,
        currentPupilId,
    } = React.useContext(PupilContext)
    const { setCurrentGuardians } = React.useContext(GuardianContext)
    const { user } = React.useContext(UserContext)
    const { classes, setCurrentClass } = React.useContext(ClassContext)
    const { db } = React.useContext(FirebaseContext)
    const [modalVisible, setModalVisible] = React.useState(false)
    const [modalType, setModalType] = React.useState('add')
    const [dialogVisible, setDialogVisible] = React.useState(false)

    const addPupilModal = () => {
        setModalType('add')
        setModalVisible(true)
    }

    const editPupilModal = (pupilId, guardians) => {
        setCurrentPupilId(pupilId)
        setCurrentGuardians(guardians)
        setModalType('edit')
        setModalVisible(true)
    }

    const deletePupilModal = (pupilId) => {
        setCurrentPupilId(pupilId)
        setDialogVisible(true)
    }

    const deletePupil = async () => {
        db.collection('pupils')
            .doc(currentPupilId)
            .delete()
            .catch((e) => console.log(e.message))

        const guardianSnapshot = await db
            .collection('guardians')
            .where('pupil', '==', currentPupilId)
            .get()

        guardianSnapshot.forEach((doc) => {
            doc.ref.delete()
        })

        reloadPupils()
        setDialogVisible(false)
    }

    const getClasses = React.useCallback(() => {
        if (classes) {
            const mappedClasses = []

            classes.forEach((item) => {
                mappedClasses.push({
                    label: item.name,
                    value: item,
                })
            })

            return mappedClasses
        }
    }, [classes])

    const handleChange = (event) => {
        setCurrentClass(event.target.value)
    }

    const renderPupil = (item) => (
        <PupilItem
            key={item.id}
            editHandler={editPupilModal}
            deleteHandler={deletePupilModal}
            item={item}
            reload={reloadPupils}
        />
    )

    return (
        <>
            <Box
                className="shadowFX"
                sx={{
                    minHeight: 'auto',
                    bgcolor: 'white',
                    borderRadius: 3,
                    p: 5,
                    position: 'relative',
                }}
            >
                <Typography fontWeight="600" sx={{ fontSize: 26, mb: 5 }}>
                    {title}
                </Typography>
                {user?.role !== 'admin' ||
                    (user?.role !== 'superadmin' && (
                        <Box sx={{ mb: 3 }}>
                            <Typography fontSize="medium">
                                Class: {currentClass?.name}
                            </Typography>
                        </Box>
                    ))}
                {user?.role !== 'teacher' && (
                    <Select
                        variant="outlined"
                        autoSize
                        sx={{
                            minWidth: 250,
                            bgcolor: 'white',
                            borderWidth: 0,
                            mb: 3,
                        }}
                        id="class"
                        onChange={handleChange}
                    >
                        {getClasses()?.map((item, index) => (
                            <MenuItem
                                sx={{ bgcolor: 'white' }}
                                key={`class-selector-item-${index}`}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                <List>{pupils?.map((item, index) => renderPupil(item))}</List>
                <Box
                    sx={{
                        flexGrow: 1,
                        bgcolor: '#eee',
                        height: 2,
                        mt: 10,
                        ml: -5,
                        mr: -5,
                    }}
                />
                <Box
                    sx={{
                        alignSelf: 'flex-end',
                        justifyContent: 'center',
                        display: 'flex',
                        mt: 4,
                    }}
                >
                    <Button
                        onClick={() => addPupilModal()}
                        variant="contained"
                        sx={{ mb: 2, color: 'white', py: 2 }}
                    >
                        ADD STUDENT
                    </Button>
                </Box>
            </Box>
            <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
                {modalType === 'add' ? (
                    <AddPupil closeModal={setModalVisible} />
                ) : (
                    <EditPupil closeModal={setModalVisible} />
                )}
            </Modal>
            <Dialog open={dialogVisible}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Delete pupil.`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogVisible(false)}>
                        CANCEL
                    </Button>
                    <Button onClick={deletePupil} autoFocus>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Pupils
