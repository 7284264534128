import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const GuardianItem = (props) => {
    const { item } = props

    return (
        <Box sx={{ my: 2 }}>
            <Typography fontSize={12}>
                {item.relation} - {item.firstname} {item.lastname}
            </Typography>
            <Typography fontSize="small">{item.cell}</Typography>
        </Box>
    )
}

export default GuardianItem
