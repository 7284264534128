import React from 'react'
import { UserContext } from './UserContext'
import { FirebaseContext } from './FirebaseContext'

export const LocationContext = React.createContext(null)

export const LocationProvider = ({ children }) => {
    const { db } = React.useContext(FirebaseContext)
    const { user, getUserById } = React.useContext(UserContext)
    const [location, setLocation] = React.useState(null)
    const [reload, setReload] = React.useState(0)
    const [reloadAll, setReloadAll] = React.useState(0)
    const [allLocations, setAllLocations] = React.useState(null)

    React.useEffect(() => {
        if (user?.location) {
            db.collection('locations')
                .doc(user?.location)
                .get()
                .then((doc) => {
                    setLocation({ id: doc.id, ...doc.data() })
                })
                .catch((e) => console.log(e))
        }
    }, [user?.location, reload])

    React.useEffect(() => {
        if (user?.role === 'superadmin') {
            getAllLocations()
        }
    }, [user?.role, reloadAll])

    const updateLocation = (data) => {
        db.collection('locations')
            .doc(location?.id)
            .update(data)
            .then(() => {
                revalidate()
            })
            .catch((e) => console.log(e))
    }

    const addAdminToLocation = (adminId, locId) => {
        db.collection('locations')
            .doc(locId)
            .update({ admin: adminId })
            .catch((e) => console.log(e.message))
    }

    const getAdminUserById = async (adminId) => {
        const userResponse = await getUserById(adminId)

        return userResponse
    }

    const createNewLocation = (data) => {
        return db
            .collection('locations')
            .add(data)
            .catch((e) => console.log(e))
    }

    const getAllLocations = () => {
        db.collection('locations')
            .get()
            .then((locQuery) => {
                const locArray = []

                locQuery.forEach((locDoc) => {
                    locArray.push({ id: locDoc.id, ...locDoc.data() })
                })

                setAllLocations(locArray)
            })
            .catch((e) => console.log(e))
    }

    const setLocationById = async (locId) => {
        const locDoc = await db.collection('locations').doc(locId).get()

        setLocation({ id: locDoc.id, ...locDoc.data() })
    }

    const deleteLocation = (item) => {
        db.collection('locations').doc(item.id).delete()
        revalidateAll()
    }

    const revalidate = () => {
        setReload((prev) => prev + 1)
    }

    const revalidateAll = () => {
        setReloadAll((prev) => prev + 1)
    }

    return (
        <LocationContext.Provider
            value={{
                location,
                updateLocation,
                createNewLocation,
                getAllLocations,
                revalidateAll,
                allLocations,
                deleteLocation,
                getAdminUserById,
                setLocation,
                setLocationById,
                reloadAll,
                revalidate,
                addAdminToLocation,
            }}
        >
            {children}
        </LocationContext.Provider>
    )
}
