import React from 'react'
import { FirebaseContext } from './FirebaseContext'
import { LocationContext } from './LocationContext'
import { UserContext } from './UserContext'

export const ClassContext = React.createContext(null)

export const ClassProvider = ({ children }) => {
    const { db } = React.useContext(FirebaseContext)
    const { location } = React.useContext(LocationContext)
    const { userId, user } = React.useContext(UserContext)
    const [currentClass, setCurrentClass] = React.useState(null)
    const [classes, setClasses] = React.useState(null)
    const [reload, setReload] = React.useState(0)

    React.useEffect(() => {
        if (location?.id && user?.role !== 'teacher') {
            const classMap = []

            db.collection('classes')
                .where('location', '==', location?.id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        classMap.push({ id: doc.id, ...doc.data() })
                    })

                    setClasses(classMap)

                    if (classMap.length) {
                        setClassById(classMap[0].id)
                    }
                })
                .catch((e) => console.log(e))
        }
    }, [location?.id, reload, user?.role, db])

    React.useEffect(() => {
        if (userId && user?.role === 'teacher') {
            getTeachersClassList(userId)
        }
    }, [userId, user])

    const getClassByTeacherId = (id) => {
        db.collection('classes')
            .where('teacher', '==', id)
            .limit(1)
            .get()
            .then((query) => {
                query.forEach((doc) => {
                    setCurrentClass({ id: doc.id, ...doc.data() })
                })
            })
            .catch((e) => {
                console.log(e)
                return null
            })
    }

    const getTeachersClass = async (id) => {
        const classQuery = await db
            .collection('classes')
            .where('teacher', '==', id)
            .limit(1)
            .get()

        return { id: classQuery?.docs[0]?.id, ...classQuery?.docs[0]?.data() }
    }

    const getTeachersClassList = async (id) => {
        const classList = []

        const classQuery = await db
            .collection('classes')
            .where('teacher', '==', id)
            .get()

        classQuery.forEach((classDoc) => {
            classList.push({ id: classDoc.id, ...classDoc.data() })
        })

        setClasses(classList)
    }

    const setClassById = async (id) => {
        const classData = await db.collection('classes').doc(id).get()

        setCurrentClass({ id: classData.id, ...classData.data() })
    }

    const getClassById = async (id) => {
        const classData = await db.collection('classes').doc(id).get()

        return { id: classData.id, ...classData.data() }
    }

    const reloadClasses = () => {
        setReload((prev) => prev + 1)
    }

    return (
        <ClassContext.Provider
            value={{
                getClassByTeacherId,
                classes,
                currentClass,
                setCurrentClass,
                reloadClasses,
                getTeachersClass,
                getTeachersClassList,
                setClassById,
                getClassById,
            }}
        >
            {children}
        </ClassContext.Provider>
    )
}
