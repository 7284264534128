import { Box } from '@mui/system'
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    Modal,
    Typography,
} from '@mui/material'
import React from 'react'
import { ClassContext } from '../../contexts/ClassContext'
import ClassItem from './components/ClassItem'
import AddClass from './modals/AddClass'
import EditClass from './modals/EditClass'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import '../../App.css'

const Classes = (props) => {
    const { title } = props
    const { classes, setCurrentClass, reloadClasses, currentClass } =
        React.useContext(ClassContext)
    const { db } = React.useContext(FirebaseContext)
    const [modalVisible, setModalVisible] = React.useState(false)
    const [modalType, setModalType] = React.useState('add')
    const [dialogVisible, setDialogVisible] = React.useState(false)

    const addClassModal = () => {
        setModalType('add')
        setModalVisible(true)
    }

    const editClassModal = (classItem) => {
        setCurrentClass(classItem)
        setModalType('edit')
        setModalVisible(true)
    }

    const deleteClassModal = (classItem) => {
        setCurrentClass(classItem)
        setDialogVisible(true)
    }

    const deleteClass = () => {
        db.collection('classes')
            .doc(currentClass.id)
            .delete()
            .catch((e) => console.log(e.message))

        reloadClasses()
        setDialogVisible(false)
    }

    const renderClass = (item) => (
        <ClassItem
            key={`class-list-${item.id}`}
            editHandler={editClassModal}
            deleteHandler={deleteClassModal}
            item={item}
            reload={reloadClasses}
        />
    )

    return (
        <>
            <Box
                className="shadowFX"
                sx={{
                    minHeight: 'auto',
                    bgcolor: '#FFF',
                    borderRadius: 3,
                    p: 5,
                    position: 'relative',
                }}
            >
                <Typography fontWeight="600" sx={{ fontSize: 26, mb: 5 }}>
                    {title}
                </Typography>
                <List>{classes?.map((item, index) => renderClass(item))}</List>
                <Box
                    sx={{
                        flexGrow: 1,
                        bgcolor: '#eee',
                        height: 2,
                        mt: 10,
                        ml: -5,
                        mr: -5,
                    }}
                />
                <Box
                    sx={{
                        alignSelf: 'flex-end',
                        justifyContent: 'center',
                        display: 'flex',
                        mt: 4,
                    }}
                >
                    <Button
                        onClick={() => addClassModal()}
                        variant="contained"
                        sx={{ mb: 2, color: 'white', py: 2 }}
                    >
                        ADD CLASS
                    </Button>
                </Box>
            </Box>
            <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
                {modalType === 'add' ? (
                    <AddClass closeModal={setModalVisible} />
                ) : (
                    <EditClass closeModal={setModalVisible} />
                )}
            </Modal>
            <Dialog open={dialogVisible}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Delete class ${currentClass?.name}.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogVisible(false)}>
                        CANCEL
                    </Button>
                    <Button onClick={deleteClass} autoFocus>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Classes
