import { Button, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import logo from '../assets/arrivedlogo.jpg'
import '../App.css'

const Login = (props) => {
    const { signIn } = React.useContext(AuthContext)
    const history = useHistory()

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        const response = await signIn(data.get('email'), data.get('password'))

        if (response) {
            history.push('/home')
        }
    }

    return (
        <Box
            flexDirection="column"
            className="shadowFX"
            sx={{
                m: 5,
                borderRadius: 3,
                bgcolor: 'primary.main',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <img style={{ margin: 20 }} width="150px" src={logo} alt="logo" />
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                    m: 1,
                    bgcolor: 'white',
                    width: '40%',
                    minWidth: 200,
                    p: 2,
                    borderRadius: 2,
                }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    LOGIN
                </Button>
            </Box>
        </Box>
    )
}

export default Login
