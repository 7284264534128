import {
    Card,
    CardContent,
    IconButton,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { TeachersContext } from '../../../contexts/TeachersContext'
import EditIcon from '@mui/icons-material/Edit'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { ClassContext } from '../../../contexts/ClassContext'

const RollcallItem = (props) => {
    const { item, onEdit } = props
    const { getClassById } = React.useContext(ClassContext)
    const [classInfo, setClassInfo] = React.useState()

    React.useEffect(() => {
        ;(async () => {
            const response = await getClassById(item.class)

            setClassInfo(response)
        })()
    }, [item?.class])

    return (
        <TableRow
            key={item.name}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell component="th" scope="row">
                {classInfo?.name}
            </TableCell>
            <TableCell component="th" scope="row">
                {`${item.calltime}`}
            </TableCell>
            <TableCell component="th" scope="row">
                {classInfo?.active ? 'Active' : 'Not Active'}
            </TableCell>
            <TableCell>
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={() => onEdit(item)}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default RollcallItem
