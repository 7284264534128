import * as React from 'react'
import firebase from 'firebase'
import { FirebaseContext } from './FirebaseContext'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    const [userId, setUserId] = React.useState(null)
    const { setErrorMessage } = React.useContext(FirebaseContext)

    const signIn = async (email, password) => {
        try {
            const userCredential = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)

            const user = userCredential.user
            setUserId(user?.uid)

            return true
        } catch (e) {
            console.log('SIGN IN ERROR: ', e)
            setErrorMessage(e.message)
            return false
        }
    }

    const signOut = () => {
        firebase
            .auth()
            .signOut()
            .then(() => setUserId(null))
            .catch((e) => {
                console.log('SIGN OUT ERROR: ', e)
                setErrorMessage(e.message)
            })
    }

    const createUser = (email, password) => {
        return firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .catch((e) => {
                console.log(e)
                setErrorMessage(e.message)
            })
    }

    const updateAuthUser = (email, password = false) => {
        const user = firebase.auth().currentUser

        user?.updateEmail(email)
            .then(() => {
                if (password) {
                    user.updatePassword(password).catch((e) =>
                        console.log(e.message)
                    )
                }
            })
            .catch((e) => {
                console.log(e.message)
                setErrorMessage(e.message)
            })
    }

    const getCurrentAuthUser = () => {
        return firebase.auth().currentUser
    }

    return (
        <AuthContext.Provider
            value={{
                userId,
                setUserId,
                signIn,
                signOut,
                createUser,
                updateAuthUser,
                getCurrentAuthUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
