import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TimePicker from '@mui/lab/TimePicker'
import {
    Button,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material'
import { Box } from '@mui/system'

import { ClassContext } from '../../../contexts/ClassContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { LocationContext } from '../../../contexts/LocationContext'
import { TeachersContext } from '../../../contexts/TeachersContext'

const EditClass = React.forwardRef((props, ref) => {
    const { db } = React.useContext(FirebaseContext)
    const { closeModal } = props
    const [classTeacher, setClassTeacher] = React.useState()
    const [teacherSelectedIndex, setTeacherSelectedIndex] = React.useState()
    const [teacherList, setTeacherList] = React.useState([])
    const [cutoffTime, setCutoffTime] = React.useState()
    const { currentClass, reloadClasses } = React.useContext(ClassContext)
    const [classActive, setClassActive] = React.useState(undefined)
    const { location } = React.useContext(LocationContext)
    const { teachers, reloadTeachers, currentTeacher, getTeacherById } =
        React.useContext(TeachersContext)

    React.useEffect(() => {
        if (currentClass) {
            setCutoffTime(currentClass.cutoff)
        }
    }, [currentClass])

    React.useEffect(() => {
        if (currentClass) {
            setClassActive(currentClass.active)
            ;(async () => {
                const response = await getTeacherById(currentClass.teacher)
                setClassTeacher(response)

                const teacherIndex = teachers.findIndex(
                    (el) => el.id === currentClass.teacher
                )
                setTeacherSelectedIndex(teacherIndex)
            })()
        }
    }, [currentClass])

    React.useEffect(() => {
        if (teachers) {
            const mappedTeachers = []

            teachers.forEach((item) => {
                mappedTeachers.push({
                    label: `${item.firstname} ${item.lastname}`,
                    value: item.id,
                })
            })

            setTeacherList(mappedTeachers)
        }
    }, [teachers])

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        await db
            .collection('classes')
            .doc(currentClass.id)
            .update({
                name: data.get('name'),
                location: location.id,
                teacher: classTeacher ? classTeacher : currentClass?.teacher,
                cutoff: data.get('cutoff'),
                active: classActive,
            })

        reloadClasses()
        reloadTeachers()
        closeModal(false)
    }

    const handleChange = (event) => {
        setClassTeacher(event.target.value)
    }

    const handleTimeChange = (newValue) => {
        setCutoffTime(newValue)
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Edit Class
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Class name"
                    name="name"
                    autoFocus
                    defaultValue={currentClass?.name}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    required
                    id="cutoff"
                    type="time"
                    label="Cut off time"
                    name="cutoff"
                    autoFocus
                    defaultValue={currentClass?.cutoff}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                        Teacher
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="teacher"
                        onChange={handleChange}
                        value={currentClass?.teacher}
                        displayEmpty
                    >
                        {teacherList?.map((item, index) => (
                            <MenuItem
                                key={`teachers-edit-${index}`}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControlLabel
                    sx={{ mt: 3 }}
                    control={
                        <Switch
                            checked={classActive}
                            onChange={(event) => setClassActive(!classActive)}
                            name="active"
                        />
                    }
                    label="Activate class"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    UPDATE
                </Button>
            </Box>
        </Box>
    )
})

export default EditClass
