import React from 'react'
import { LocationContext } from '../../../contexts/LocationContext'
import { UserContext } from '../../../contexts/UserContext'
import { AuthContext } from '../../../contexts/AuthContext'
import { millisecondsToMinutes, minutesToMilliseconds } from 'date-fns'
import { Box } from '@mui/system'
import { Button, IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const EditAdmin = React.forwardRef((props, ref) => {
    const { location, updateLocation } = React.useContext(LocationContext)
    const { user, updateUser } = React.useContext(UserContext)
    const { updateAuthUser, getCurrentAuthUser } = React.useContext(AuthContext)
    const [passError, setPassError] = React.useState(false)
    const { closeModal } = props

    const handleSubmit = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const email = data.get('email')
        const password = data.get('password')
        const confirmPassword = data.get('confirm-password')

        if (password && confirmPassword !== password) {
            setPassError(true)
            return
        }

        updateLocation({
            name: data.get('name'),
        })
        updateUser({
            firstname: data.get('firstname'),
            lastname: data.get('lastname'),
            email,
        })
        updateAuthUser(email, password)

        closeModal(false)
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Edit School
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="School name"
                    name="name"
                    autoFocus
                    defaultValue={location?.name}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="Admin first name"
                    name="firstname"
                    autoFocus
                    defaultValue={user?.firstname}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Admin last name"
                    name="lastname"
                    autoFocus
                    defaultValue={user?.lastname}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Admin email"
                    name="email"
                    autoFocus
                    defaultValue={user?.email}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Admin password"
                    name="password"
                    autoComplete="current-password"
                    type="password"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="confirm-password"
                    label="Confirm password"
                    name="confirm-password"
                    autoComplete="current-password"
                    type="password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    UPDATE
                </Button>
            </Box>
        </Box>
    )
})

export default EditAdmin
