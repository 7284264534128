import { Box } from '@mui/system'
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    Modal,
    Typography,
} from '@mui/material'
import React from 'react'
import { TeachersContext } from '../../contexts/TeachersContext'
import TeacherItem from './components/TeacherItem'
import EditTeacher from './modals/EditTeacher'
import AddTeacher from './modals/AddTeacher'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import '../../App.css'

const Teacher = (props) => {
    const { title } = props
    const { teachers, setCurrentTeacher, reloadTeachers, currentTeacher } =
        React.useContext(TeachersContext)
    const { db } = React.useContext(FirebaseContext)
    const [modalVisible, setModalVisible] = React.useState(false)
    const [dialogVisible, setDialogVisible] = React.useState(false)
    const [modalType, setModalType] = React.useState('add')

    const addTeacherModal = () => {
        setModalType('add')
        setModalVisible(true)
    }

    const editTeacherModal = (teacher) => {
        setModalType('edit')
        setCurrentTeacher(teacher)
        setModalVisible(true)
    }

    const deleteTeacherModal = (teacher) => {
        setCurrentTeacher(teacher)
        setDialogVisible(true)
    }

    const deleteTeacher = async () => {
        await db
            .collection('users')
            .doc(currentTeacher.id)
            .delete()
            .catch((e) => console.log(e.message))

        reloadTeachers()
        setDialogVisible(false)
    }

    const getTeachers = React.useCallback(() => {
        if (teachers) {
            console.log(teachers)
            return teachers
        }
    }, [teachers, reloadTeachers])

    const renderTeacher = (item) => (
        <TeacherItem
            key={item.id}
            editHandler={editTeacherModal}
            deleteHandler={deleteTeacherModal}
            item={item}
            reload={reloadTeachers}
            closeModal={() => setModalVisible(false)}
        />
    )

    return (
        <>
            <Box
                className="shadowFX"
                sx={{
                    minHeight: 'auto',
                    bgcolor: 'white',
                    borderRadius: 3,
                    p: 5,
                    position: 'relative',
                }}
            >
                <Typography fontWeight="600" sx={{ fontSize: 26, mb: 5 }}>
                    {title}
                </Typography>
                <List>
                    {getTeachers()?.map((item, index) => renderTeacher(item))}
                </List>
                <Box
                    sx={{
                        flexGrow: 1,
                        bgcolor: '#eee',
                        height: 2,
                        mt: 10,
                        ml: -5,
                        mr: -5,
                    }}
                />
                <Box
                    sx={{
                        alignSelf: 'flex-end',
                        justifyContent: 'center',
                        display: 'flex',
                        mt: 4,
                    }}
                >
                    <Button
                        onClick={() => addTeacherModal()}
                        variant="contained"
                        sx={{ mb: 2, color: 'white', py: 2 }}
                    >
                        ADD TEACHER
                    </Button>
                </Box>
            </Box>
            <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
                {modalType === 'add' ? (
                    <AddTeacher closeModal={setModalVisible} />
                ) : (
                    <EditTeacher closeModal={setModalVisible} />
                )}
            </Modal>
            <Dialog open={dialogVisible}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Delete teacher {currentTeacher?.firstname}{' '}
                        {currentTeacher?.lastname}.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogVisible(false)}>
                        CANCEL
                    </Button>
                    <Button onClick={deleteTeacher} autoFocus>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Teacher
