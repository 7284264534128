import React from 'react'
import { PupilContext } from '../../../contexts/PupilContext'
import { FirebaseContext } from '../../../contexts/FirebaseContext'
import { GuardianContext } from '../../../contexts/GuardianContext'
import GuardianItemPanel from '../components/GuardianItemPanel'
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

const EditPupil = React.forwardRef((props, ref) => {
    const { db } = React.useContext(FirebaseContext)
    const { closeModal, guardians } = props
    const { currentPupilId, reloadPupils } = React.useContext(PupilContext)
    const { currentGuardians, setCurrentGuardians } =
        React.useContext(GuardianContext)
    const [pupil, setPupil] = React.useState(null)

    React.useEffect(() => {
        if (currentPupilId) {
            db.collection('pupils')
                .doc(currentPupilId)
                .get()
                .then((doc) => setPupil({ id: doc.id, ...doc.data() }))
        }
    }, [currentPupilId])

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        await db
            .collection('pupils')
            .doc(currentPupilId)
            .update({
                firstname: data.get('firstname'),
                lastname: data.get('lastname'),
            })

        reloadPupils()
        closeModal(false)
    }

    if (!pupil) {
        return null
    }

    return (
        <Box ref={ref}>
            <Box
                sx={{
                    bgcolor: 'white',
                    minWidth: '30%',
                    p: 5,
                    boxShadow: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 4,
                }}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                        sx={{ bgcolor: 'white' }}
                        size="large"
                        onClick={() => closeModal(false)}
                    >
                        <CloseIcon
                            size="large"
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontSize: 'h6.fontSize',
                        fontWeight: 'normal',
                        mb: 2,
                    }}
                >
                    Edit Student
                </Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First name"
                    name="firstname"
                    defaultValue={pupil?.firstname}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last name"
                    name="lastname"
                    defaultValue={pupil?.lastname}
                    autoFocus
                />
                <GuardianItemPanel
                    currentGuardians={currentGuardians}
                    currentPupilId={currentPupilId}
                    reloadPupils={reloadPupils}
                    closeModal={closeModal}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'white', py: 2 }}
                >
                    EDIT
                </Button>
            </Box>
        </Box>
    )
})

export default EditPupil
