import { Card, CardContent, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone'
import BackspaceIcon from '@mui/icons-material/Backspace'

const ClassItem = (props) => {
    const { item, editHandler, reload, deleteHandler } = props

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography variant="body1">{item?.name}</Typography>
                    <Typography sx={{ fontSize: 11 }}>
                        Class cut off time: {item?.cutoff}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: item?.active
                                ? 'success.light'
                                : 'error.main',
                        }}
                    >
                        {item?.active ? 'ACTIVE' : 'DEACTIVED'}
                    </Typography>
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <IconButton
                        size="small"
                        sx={{ color: 'text.secondary' }}
                        onClick={() => editHandler(item)}
                    >
                        <ModeEditTwoToneIcon fontSize="medium" />
                    </IconButton>
                    <IconButton
                        size="small"
                        sx={{ color: 'text.primary' }}
                        onClick={() => deleteHandler(item)}
                    >
                        <BackspaceIcon fontSize="small" />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    )
}

export default ClassItem
