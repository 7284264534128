import {
    endOfToday,
    startOfWeek,
    format,
    eachDayOfInterval,
    subYears,
    subWeeks,
    startOfMonth,
    subMonths,
    startOfYear,
} from 'date-fns'
import React from 'react'
import { ClassContext } from './ClassContext'
import { PupilContext } from './PupilContext'
import { FirebaseContext } from './FirebaseContext'

export const RollcallContext = React.createContext(null)

export const RollcallProvider = ({ children }) => {
    const { db } = React.useContext(FirebaseContext)
    const [currentRollId, setCurrentRollId] = React.useState()
    const [rollcallEvents, setRollcallEvents] = React.useState(null)
    const [range, setRange] = React.useState('weekly')
    const [startIndex, setStartIndex] = React.useState(0)

    const getTeacherRollcalls = async (teacherId) => {
        try {
            const query = await db
                .collection('classes')
                .where('teacher', '==', teacherId)
                .limit(1)
                .get()
            let classId

            query.forEach((doc) => (classId = doc.id))

            const rollcallDocs = await db
                .collection('rollcalls')
                .where('class', '==', classId)
                .orderBy('calltime', 'desc')
                .get()

            const rcArray = []
            rollcallDocs.forEach((call) => {
                rcArray.push({ ...call.data(), id: call.id })
            })

            return rcArray
        } catch (e) {
            console.log(e.message)
        }
    }

    const getPupilsByRollId = async (rollId) => {
        try {
            const pupilsQuery = await db
                .collection('rollcalls')
                .doc(rollId)
                .collection('pupils')
                .get()

            const pupilsMapped = {}
            pupilsQuery.forEach((pupilDoc) => {
                console.log(pupilDoc.id, pupilDoc.data())
                pupilsMapped[pupilDoc.id] = pupilDoc.data()
            })

            return pupilsMapped
        } catch (e) {
            console.log(e.message)
        }
    }

    const getRollcallPupilById = async (pupilId) => {
        try {
            const pupilDoc = await db
                .collection('rollcalls')
                .doc(currentRollId)
                .collection('pupils')
                .doc(pupilId)
                .get()

            return pupilDoc.data().arrived
        } catch (e) {
            console.log(e.message)
        }
    }

    const createNewRollcall = (time, classId, locationId) => {
        db.collection('rollcalls')
            .add({
                calltime: time,
                class: classId,
                location: locationId,
            })
            .then((docRef) => {
                setCurrentRollId(docRef.id)
            })
            .catch((e) => console.log(e))
    }

    const updateRollcallPupils = (data) => {
        if (currentRollId) {
            Object.keys(data).forEach((item) => {
                db.collection('rollcalls')
                    .doc(currentRollId)
                    .collection('pupils')
                    .doc(item)
                    .set(data[item])
                    .catch((e) => console.log(e.message))
            })
        }
    }

    const deleteRollcall = async (callId) => {
        try {
            await db.collection('rollcalls').doc(callId).delete()

            return true
        } catch (e) {
            console.log(e.message)
        }
    }

    const getRollcallsByDay = (locationId) => {
        db.collection('rollcalls')
            .where('location', '==', locationId)
            .where('calltime', '>=', getDateRange())
            .orderBy('calltime', 'desc')
            .get()
            .then((querySnapshot) => {
                const eventsByKey = {}

                querySnapshot.forEach((doc) => {
                    const docData = doc.data()
                    const docId = doc.id

                    const docTime = format(
                        new Date(docData.calltime),
                        getDateKey()
                    )

                    if (eventsByKey.hasOwnProperty(docTime)) {
                        eventsByKey[docTime].push(docData)
                    } else {
                        eventsByKey[docTime] = [docData]
                    }
                })

                console.log('Events by key: ', eventsByKey)

                setRollcallEvents(eventsByKey)
            })
            .catch((e) => console.log('Rollcall error: ', e.message))
    }

    const getDateRange = () => {
        let selectedDate
        const currentDate = endOfToday(new Date())

        switch (range) {
            case 'weekly':
                selectedDate = startOfWeek(subWeeks(currentDate, startIndex))
                break
            case 'monthly':
                selectedDate = startOfMonth(subMonths(currentDate, startIndex))
                break
            case 'yearly':
                selectedDate = startOfYear(subYears(currentDate, startIndex))
                break
        }

        return format(selectedDate, 'MM/dd/yyyy HH:MM:SS')
    }

    const getDateKey = () => {
        switch (range) {
            case 'weekly':
                return 'MM/dd/yyyy'
            case 'monthly':
                return 'MMM'
            case 'yearly':
                return 'yyyy'
        }
    }

    const getRollcallByClassId = async (classId) => {
        const rollcallDocs = await db
            .collection('rollcalls')
            .where('class', '==', classId)
            .orderBy('calltime', 'desc')
            .get()

        const rcArray = []
        rollcallDocs.forEach((call) => {
            rcArray.push({ id: call.id, ...call.data() })
        })

        return rcArray
    }

    const getRollcallById = async (id) => {
        const rollResponse = await db.collection('rollcalls').doc(id).get()

        return { id, ...rollResponse.data() }
    }

    return (
        <RollcallContext.Provider
            value={{
                createNewRollcall,
                currentRollId,
                getRollcallsByDay,
                rollcallEvents,
                range,
                setRange,
                startIndex,
                setStartIndex,
                getTeacherRollcalls,
                setCurrentRollId,
                deleteRollcall,
                getRollcallPupilById,
                getPupilsByRollId,
                updateRollcallPupils,
                getRollcallById,
                getRollcallByClassId,
            }}
        >
            {children}
        </RollcallContext.Provider>
    )
}
